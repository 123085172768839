<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Burnup Chart" />

    <span style="margin-right: 10px">
      Current Project
      <a-dropdown :trigger="['click']">
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in pojectList" :key="item.id" @click="current = item">
              {{ item.name }}({{ item.id }})
            </a-menu-item>
          </a-menu>
        </template>
        <a-button style="min-width: 100px">
          {{ current.name }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </span>
    <span style="margin-right: 10px" v-if="milestoneList[0] && !stonesLoading">
      Current Milestone
      <a-dropdown :trigger="['click']">
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in milestoneList" :key="item.id" @click="currentStone = item">
              {{ item.title }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button style="min-width: 100px">
          {{ currentStone.title }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </span>
    <a-spin v-if="stonesLoading" />

    <div class="mt-30" v-if="currentStone.id && !stonesLoading">
      <BurnupChart :id="currentStone.id" />
    </div>
    <a-empty v-else style="margin-top: 100px" />
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";

import {
  DownOutlined,
  BarChartOutlined,
  TableOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import BurnupChart from "@/components/chart/BurnupChart";
import Datepicker from "@/components/DatePick";
export default {
  components: {
    BurnupChart,

    DownOutlined,
    Datepicker,
    TableOutlined,
    BarChartOutlined,
  },

  setup() {
    const { $msg, gql } = inject("$");

    const v = reactive({
      currentStone: {},
    });

    const QL = gql`query {projects {id name}}`;

    const {
      result: projectsRes,
      onResult: onProjectRes,
      loading: pojectListloading,
      onError: onProjectErr,
    } = useQuery(QL, null, { fetchPolicy: "network-only" });

    const pojectList = useResult(projectsRes, null, (data) => data.projects);

    const current = ref({});

    const enabled = ref(false);

    onProjectRes((res) => {
      if (!res.data) return;
      current.value = res.data.projects[0] ? res.data.projects[0] : {};
      if (res.data.projects[0]) {
        enabled.value = true;
      } else {
        $msg.warning("Have no project");
      }
    });
    onProjectErr((error) => {
      $msg.error("Failed to fetch projects");
    });

    const QUERY_PROJECT = gql`
      query ($projectId: Int) {
        milestoneByProjectId(projectId: $projectId) {
          
            title
            startDate
            dueDate
            id
          
        }
      }
    `;

    const currentStone = ref({});

    const {
      result: projectRes,
      onResult: onprojectRes,
      loading: stonesLoading,
      onError,
    } = useQuery(
      QUERY_PROJECT,
      () => ({
        projectId: current.value.id || 162,
      }),
      { fetchPolicy: "network-only" }
    );
    const milestoneList = useResult(projectRes, [], (data) =>
      data.project.milestones.filter((e) => e.dueDate && e.startDate)
    );

    onprojectRes((res) => {
      if (!res.data) return;
      currentStone.value = res.data.project.milestones.filter(
        (e) => e.dueDate && e.startDate
      )[0]
        ? res.data.project.milestones.filter((e) => e.dueDate && e.startDate)[0]
        : {};
      if (!res.data.project.milestones[0]) {
        $msg.warning("Have no Milestones");
      }
    });

    onError((error) => {
      $msg.error("Failed to fetch data");
    });

    function dateUpdate(params) {
      console.log(params);
      let { dimension, start, end } = params;
      v.dimension = dimension;
      v.start = start;
      v.end = end;
    }

    const list = [];
    return {
      ...toRefs(v),
      stonesLoading,
      pojectList,
      pojectListloading,
      current,
      dateUpdate,
      enabled,
      milestoneList,
      currentStone,
      list,
    };
  },
};
</script>
<style lang="scss" scoped></style>
