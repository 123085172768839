<template>
  <div id="chart"></div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted, watch } from "vue";
import echarts from "@/utils/echarts.js";
import { useQuery, useResult } from "@vue/apollo-composable";

import * as dayjs from "dayjs";
var dayOfYear = require("dayjs/plugin/dayOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export default {
  props: {
    id: { Type: Number },
  },
  components: {},
  setup(props) {
    const { $msg, gql } = inject("$");

    // watch(props, refetch, { deep: true });

    const QL = gql`
      query ($milestoneId: Int) {
        milestoneBurn(milestoneId: $milestoneId) {
          open
          completed
          total
          date
        }
      }
    `;

    const { result, onResult, loading, onError, refetch } = useQuery(
      QL,
      () => ({
        milestoneId: props.id,
      }),
      {
        enabled: props.id,
        fetchPolicy: "network-only",
      }
    );

    onResult((queryResult) => {
      if (!queryResult.data) return;
      echartInit();
    });

    let myChart;
    function echartInit() {
      // if(!props.id)return
      if (!result.value || !result.value.milestoneBurn) return;

      let { milestoneBurn } = result.value;

      if (milestoneBurn.length == 0) return;

      let date = milestoneBurn.map((e) => dayjs(e.date).format("DD MMM YYYY"));
      let opened = milestoneBurn.map((e) => e.open);
      let closed = milestoneBurn.map((e) => e.completed);
      let total = milestoneBurn.map((e) => e.total);

      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }

      let option = {
        title: [
          { text: "Burndown chart" },
          {
            text: "Burnup chart",
            left: "55%",
            right: "5%",
          },
        ],
        legend: [
          {
            data: ["Open Issue"],
            left: "5%",
            bottom: "35F%",
          },
          {
            data: ["Total", "Completed"],
            left: "55%",
            bottom: "35%",
          },
        ],
        grid: [
          {
            left: "5%",
            right: "55%",
            top: "7%",
            bottom: "45%",
          },
          {
            left: "55%",
            right: "5%",
            top: "7%",
            bottom: "45%",
          },
        ],
        tooltip: {
          trigger: "axis",
        },

        xAxis: [
          {
            type: "category",
            data: date,
          },
          {
            type: "category",
            data: date,
            gridIndex: 1,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Total Issue",
            axisLine: {
              show: true,
            },
          },
          {
            type: "value",
            name: "Total Issue",
            gridIndex: 1,
            axisLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            name: "Open Issue",
            data: opened,
            type: "line",
          },
          {
            name: "Total",
            data: total,
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            name: "Completed",
            data: closed,
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
        ],
      };

      myChart = echarts.init(document.getElementById("chart"));
      myChart.setOption(option);
      // v.myChart = myChart;
    }

    onMounted(() => {
      echartInit();
    });

    return {};
  },
};
</script>
<style lang="scss" scoped>
#chart {
  height: 850px;
  width: 100%;
  margin: auto;
}
</style>
